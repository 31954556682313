import styled from "styled-components"
import { motion } from "framer-motion"

export const HomeContentSection = styled(motion.div)`
  margin-bottom: 20rem;
`

export const Content = styled.h2`
  width: 53%;
  font-size: 3.4rem;
  font-weight: 400;
  margin-left: 12.4rem;
`
