import styled from "styled-components"
import { motion } from "framer-motion"

export const Banner = styled.div`
  background-color: ${props => props.theme.background};
  height: 100vh;
  width: 100%;
  position: relative;
  margin-bottom: 30rem;
`

export const Background = styled.div`
  height: 100%;
  width: 100%;

  video {
    object-fit: cover;
  }
`

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
`

export const BannerTitle = styled(motion.h1)`
  position: absolute;
  bottom: -120px;
  left: -18px;
  color: ${props => props.theme.text};
  pointer-events: none;
`

export const HeadLine = styled(motion.span)`
  display: block;
  font-size: 33rem;
  font-weight: 900;
  line-height: 0.76;
`
