import React from "react"
import Layout from "../components/layout"
import HomeBanner from "../components/HomeBanner/HomeBanner"
import { useGlobalDispatchContext } from "../context/Context"
import { cursorStyles } from "../constants/constants"
import HomeContent from "../components/HomeContent/HomeContent"
import HomeFeatured from "../components/HomeFeatured/HomeFeatured"

const IndexPage = props => {
  const dispatch = useGlobalDispatchContext()

  const onCursor = newCursorType => {
    newCursorType =
      (cursorStyles.includes(newCursorType) && newCursorType) || false
    dispatch({ type: "TOGGLE_CURSOR", cursorType: newCursorType })
  }
  return (
    <Layout>
      <HomeBanner onCursor={onCursor} />
      <HomeContent />
      <HomeFeatured />
    </Layout>
  )
}

export default IndexPage
